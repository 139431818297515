* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

body {
  font-family: monospace;
  margin: 0;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  font-size: 4em;
  width: 100vw;
  max-width: 800px;
  margin: auto;
  padding: 3px;
}
input {
  font-size: 1.5rem;
}
button {
  font-size: 1.7rem;
}

/* configuration */
.container {
  /* center items on the screen */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;

  /* higher center position */
  /* position: relative; */
  /* top: -5rem; */
}

.container > * {
  width: 100%;
  margin: 3px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.actions button {
  width: 50%;
  margin: 3px;
}
input,
img {
  border-radius: 10px;
}
button {
  color: white;
  border-radius: 10px;
  border: none;
  /* font-size: 3rem; */
  padding: 0.2rem 1rem 0.2rem 1rem;
  font-family: inherit;
}
button:hover {
  cursor: pointer;
}
.btn-check {
  background-color: #c7e4f6;
  margin-left: 0px;
}
.btn-check:hover {
  background-color: #b3daf2;
}
.btn-goto {
  background-color: #f90070;
  margin-right: 0px;
}
.btn-goto:hover {
  background-color: #ec006a;
}
.status {
  text-align: center;
}
#destination {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  word-wrap: break-word;
  margin-bottom: 3rem;
}
img {
  height: 30vh;
  width: auto !important;
  margin-bottom: 2rem !important;
}
.inputs {
  display: flex;
}
.inputs * {
  margin: 3px;
}
#url {
  width: 100%;
  /* border-radius: 10px 0 0 10px; */
  /* margin-right: 0px; */
}
.btn-paste {
  background-color: #777777;
  border-radius: 0 10px 10px 0;
  margin-left: 0px;
  display: none;
}
.btn-paste:hover {
  background-color: #717171;
}

h2 {
  font-size: 1.5rem;
}
ul > li,
p {
  font-size: 1rem;
}
ul > li {
  list-style: none;
  margin-left: -2rem;
}
